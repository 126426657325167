<template>
  <div>
    <!-- SECTION -->
    <section class="section section-secondary jpadding">
      <div class="jcard jcard-main jcard-nohover">
        <!-- TITLE -->
        <div class="d-flex mb-3">
          <h3>Watch Models Catalog</h3>
          <div class="ml-auto">
            <button class="btn btn-outline-primary" @click="showNewWatchModelDialog">
              <i class="fa fa-plus"></i>
            </button>
          </div>
        </div>
        <!-- Search -->
        <SearchAndFilters class="d-flex mb-3" :searchList=watchModelsList></SearchAndFilters>
        <!-- List -->
        <div class="jblock jblock-grid">
          <!-- LIST LOOP -->
          <div v-for="(model, index) in watchModelsList" :key="model.id" class="jcard-medium">
            <div class="d-flex align-items-center">
              <h5>{{ model.name }}</h5>
              <!-- <button
                @click="showEditWatchModelDialog(index)"
                class="ml-auto jbtn-text jbtn-grey jbtn-circle-hover"
              >
                <i class="fa fa-ellipsis-v"></i>
              </button> -->
              <b-dropdown right variant="link" toggle-class="jbtn jbtn-icon jbtn-icon-link" class="ml-auto" no-caret>
                <template #button-content>
                  <i class="fa fa-ellipsis-v"></i>
                </template>
                <b-dropdown-item @click="showEditWatchModelDialog(index)"><i class="fal fa-pencil mr-2"></i> Edit
                  Info</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'CreateWatchModel', params: { id: model.id } }">
                  <i class="fal fa-pencil-alt mr-2"></i> Setup Details</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'WatchModelUploads', params: { id: model.id } }">
                  <i class="fal fa-images mr-2"></i> Upload Assets</b-dropdown-item>
                <b-dropdown-item @click="deleteWatchModel(index)"><i class="fal fa-trash mr-2"></i>
                  Delete</b-dropdown-item>
              </b-dropdown>
            </div>

            <!-- <div class="jdropdown">
              <button
                @click="dropdown1 = !dropdown1"
                class="jbtn-text jbtn-grey jbtn-circle-hover "
              >
                <i class="fa fa-ellipsis-v"></i>
              </button>

              <ul v-bind:class="{ hidden: !dropdown1 }" class="jdropdown-list">
                <li><i class="fal fa-pencil"></i> Edit</li>
                <li><i class="fal fa-trash"></i> Delete</li>
              </ul>
            </div> -->

            <hr />
            <div class="form-row">
              <div class="col-md">
                <p><small>Model ID</small><br />{{ model.id.substring(0, 8) }}</p>
                <p><small>Price</small><br />{{ model.price }}€</p>
              </div>
              <div class="col-md">
                <p>
                  <small>Status</small><br />
                  <span class="badge badge-pill" v-bind:class="{
                    'badge-success': model.active,
                    'badge-danger': !model.active
                  }">{{ model.active ? "ACTIVE" : "INACTIVE" }}</span>
                </p>
                <p>
                  <small>Availability</small><br />
                  <span class="badge badge-pill" v-bind:class="{
                    'badge-success': model.status == $C.STATUS.WATCH_MODEL_AVAILABILITY.BUY || $C.STATUS.WATCH_MODEL_AVAILABILITY.WAITING_LIST,
                    'badge-warning': model.status == model.status == $C.STATUS.WATCH_MODEL_AVAILABILITY.INQUIRY,
                    'badge-danger': model.status == $C.STATUS.WATCH_MODEL_AVAILABILITY.OUT_OF_STOCK,
                  }">{{ $C.STATUS.WATCH_MODEL_AVAILABILITY_STRIGNS[model.status] }}</span>
                </p>
              </div>
            </div>
            <hr />
            <p>
              <small>{{ model.text }}</small>
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- ----- new/edit watch model modal --------------------------------- -->
    <b-modal :hide-header="true" :hide-footer="true" id="new-watch-model-modal" ref="new-watch-model-modal" centered
      title="BootstrapVue">
      <div class="jdialog-top">
        <div class="close-container">
          <h3>
            <strong>{{ editMode ? "Update" : "Create" }} Model</strong>
          </h3>
          <span @click="$bvModal.hide('new-watch-model-modal')" class="ml-auto jclose"><i class="fa fa-times "></i></span>
        </div>
        <p v-if="editMode">
          <small>Update existing watch model in the catalog.</small>
        </p>
        <p v-else><small>Add a new watch model to the catalog.</small></p>
      </div>
      <div class="jdialog-main">
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label for="name"> <i class="fal fa-tag"></i> Name</label>
              <input type="text" v-bind:class="{
                  'form-control': true,
                  'is-invalid': !validInput(editData.name) && bluredName
                }" v-on:blur="bluredName = true" v-model="editData.name" />
              <div class="invalid-feedback">
                Please enter a valid name for the watch.
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md">
            <div class="form-group">
              <label for="price"><i class="fal fa-euro-sign"></i> Price</label>
              <input type="text" v-bind:class="{
                  'form-control': true,
                  'is-invalid': !validNonTxtField(editData.price) && bluredPrice
                }" v-on:blur="bluredPrice = true" v-model="editData.price" />
              <div class="invalid-feedback">
                Please enter a valid price.
              </div>
            </div>
          </div>

          <div class="col-md">
            <div class="form-group">
              <label for="status"><i class="fal fa-exclamation-circle"></i> Status</label>
              <b-form-select v-model="editData.active"
                :options="$C.getFormattedBoolOptionsArray(['Active', 'Inactive'])"></b-form-select>
              <div class="invalid-feedback">
                Please select a status.
              </div>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="col-md">
            <div class="form-group">
              <label for="status"><i class="fal fa-exclamation-circle"></i> Availability</label>
              <b-form-select v-model="editData.status"
                :options="$C.getFormattedOptionsArray($C.STATUS.WATCH_MODEL_AVAILABILITY_STRIGNS)"></b-form-select>
              <div class="invalid-feedback">
                Please select availability.
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="text"><i class="fal fa-comment-alt"></i> Description</label>
          <textarea type="text" v-bind:class="{
              'form-control': true,
              'is-invalid': !validInput(editData.text) && bluredText
            }" v-on:blur="bluredText = true" v-model="editData.text" />
          <div class="invalid-feedback">
            Please enter a short description text.
          </div>
        </div>

        <!-- alert -->
        <b-alert v-if="showInputError" show variant="danger"><i class="fad fa-exclamation-circle"></i>
          {{ validationMsg }}
        </b-alert>
      </div>

      <!-- dialog bottom -->
      <div class="jdialog-bottom with-cancel">
        <button @click="$bvModal.hide('new-watch-model-modal')" class="jbtn jbtn-sm jbtn-red">
          <i class="fa fa-times"></i> Cancel
        </button>
        <button @click="updateWatchModel" class="jbtn jbtn-sm" :disabled="isSaving">
          <i class="fa fa-check"></i> {{ editMode ? "Update" : "Create" }}
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import SearchAndFilters from "@/components/general/SearchAndFilters"
import toast from "@/assets/js/toast";
import C from "@/const";
import firebase from "@/firebase/firebaseInit";
const db = firebase.db;

export default {
  name: "WatchCatalog",
  components: {
    SearchAndFilters,
  },
  data() {
    return {
      watchModelsList: [],

      isLoading: false,
      isSaving: false,

      //form error alert
      showInputError: false,
      validationMsg: "",

      //edit user info form
      editMode: false,
      valid: false,
      bluredName: false,
      bluredText: false,
      bluredPrice: false,

      watchModelId: "",
      editData: C.WATCH_MODEL,
    };
  },
  methods: {
    addWatchModelDocToList: function (watchModelDoc) {
      const watchModel = watchModelDoc.data();
      watchModel.id = watchModelDoc.id;
      // making sure that anything can be searched by adding the correspoinding strings to the object
      watchModel.statusName = watchModel.active ? "active" : "inactive";

      this.watchModelsList.push(watchModel);
    },
    //load all watch models list
    allWatchModels: function () {
      db.collection(C.COLLECTION.WATCH_CATALOG).onSnapshot(querySnapshot => {
        this.watchModelsList.splice(0);
        querySnapshot.forEach(doc => {
          this.addWatchModelDocToList(doc);
        });
      });
    },
    // show/init create model dialog
    showNewWatchModelDialog() {
      this.editMode = false;
      this.validationMsg = "";
      // reset valid fields
      this.bluredName = false;
      this.bluredText = false;
      this.bluredPrice = false;
      // reset data
      this.editData.name = "";
      this.editData.active = false;
      this.editData.text = "";
      this.editData.price = 0;
      this.editData.status = C.STATUS.WATCH_MODEL_AVAILABILITY.BUY;
      // open dialog
      this.$refs["new-watch-model-modal"].show();
    },
    // show/init edit model dialog
    showEditWatchModelDialog(index) {
      this.editMode = true;
      this.validationMsg = "";
      // load data
      this.editData = this.watchModelsList[index];
      this.watchModelId = this.watchModelsList[index].id;
      // open dialog
      this.$refs["new-watch-model-modal"].show();
    },
    // save watch model to db
    updateWatchModel() {
      if (!this.validate()) return;

      this.isSaving = true;

      // simple case: create new watch
      if (!this.editMode) {
        db.collection(C.COLLECTION.WATCH_CATALOG).doc().set(this.editData, { merge: true })
          .then(() => {
            this.$refs["new-watch-model-modal"].hide();
            toast.success("New watch model added to the catalog.");
            this.isSaving = false;
          })
          .catch((error) => {
            toast.error("Couldn't create new watch model. " + error.message);
            this.isSaving = false;
          });
        return;
      }

      // edit mode - needs additional checks and flags to keep the prices and products in sync with stripe
      // TODO: there is probably a better way
      db.collection(C.COLLECTION.PRODUCTS).doc(C.PRODUCT_PREFIX + this.watchModelId).get().then((snap) => {
        const product = snap.data();
        product.id = snap.id;

        // stripe sets it as string -,-
        const waitingForUpdate = (product.metadata.updateInProgress === true || product.metadata.updateInProgress === "true");
        if (waitingForUpdate) {
          console.log("Stripe synchronization still in progress");
          toast.error("Stripe synchronization in progress , please try again later");
          this.isSaving = false;
          return;
        }

        db.collection(C.COLLECTION.PRODUCTS).doc(C.PRODUCT_PREFIX + this.watchModelId).update({ "metadata.updateInProgress": true }).then(() => {
          db.collection(C.COLLECTION.WATCH_CATALOG).doc(this.watchModelId).set(this.editData, { merge: true })
            .then(() => {
              this.$refs["new-watch-model-modal"].hide();
              toast.success("Watch updated.");
              this.isSaving = false;
            })
            .catch((error) => {
              toast.error("Couldn't udpate watch. " + error.message);
              this.isSaving = false;
            });
        });
      })
    },
    deleteWatchModel(index) {
      if (confirm("Do you really want to delete this watch model?")) {
        //check if this model is already used, can only delete models that are not yet associated with watch objects
        db.collection(C.COLLECTION.WATCHES)
          .where("modelId", "==", this.watchModelsList[index].id)
          .get()
          .then(querySnapshot => {
            //dont delete
            if (!querySnapshot.empty) {
              console.log("This model is already in use and can't be deleted.");
              //TODO: add Toast
            } else {
              //delete
              var docRef = db.collection(C.COLLECTION.WATCH_CATALOG).doc(this.watchModelsList[index].id);
              docRef
                .delete()
                .then(() => {
                  console.log("Watch model successfully deleted!");
                  //TODO: add Toast
                })
                .catch(error => {
                  console.error("Error removing watch model: ", error);
                });
            }
          });
      }
    },
    validate() {
      this.bluredName = true;
      if (!this.validInput(this.editData.name)) {
        this.valid = false;
        return false;
      }
      this.bluredPrice = true;
      if (!this.validNonTxtField(this.editData.price)) {
        this.valid = false;
        return false;
      }
      this.bluredText = true;
      if (!this.validInput(this.editData.text)) {
        this.valid = false;
        return false;
      }
      this.valid = true;
      return true;
    },
    validInput(input) {
      return input.trim().length > 0;
    },
    validNonTxtField(input) {
      return input != null && input != "";
    }
  },
  mounted() {
    this.allWatchModels();
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/css/platform.scss";
</style>
